import React from 'react'
import Grid from "@material-ui/core/Grid";
import SubCatCard from './SubCatCard'
import { useSelector } from 'react-redux'


const SubCat = (props) => {
    const { Menu } = useSelector((state) => {
        return {
            Menu: state.menu.Items,
            isLoading: state.menu.isLoading
        }
    });
    const filteredMenu = Menu.filter(data => data.Cat === props.Cat)

    return (
        <Grid container justify="center">
            { filteredMenu.map((data, i) => <SubCatCard Data={data} key={i} />)}
        </Grid>
    )
}

export default SubCat
