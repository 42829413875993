import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import About from "../views/About";


const dashboardRoutes = [];
const useStyles = makeStyles(styles);

const Navbar = (props) => {
  const classes = useStyles();
  const { ...rest } = props;


  return (
    <>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="Salateen"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "primary",
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/Paralexpic.jpg")}>
        <div className={classes.container} id="About">
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>Your Food Love Story Starts With Us.</h1>
              <h4 className="fontSetting">
                The authentic recipes dating back 100s of years ago carrying traditions and culture creating a perfect blend of spices and taste. Salateen is not just food its history in a plate with perfect aroma and taste!!
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <About />
        </div>
      </div>
    </>
  );
};

export default Navbar;
