import React, { useEffect } from "react";
import "./App.css";
import Navbar from "views/Navbar.js";
// import Footer from "views/Footer";
import TabsBar from "views/Menu/TabBar";
import MenuHeader from "views/Menu/MenuHeader";
import Footer from 'components/Footer/Footer'
import { menuUpdate } from '../src/store/counterslice'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckOutForm from './views/checkoutPage';
import UserFormWithFormikMaterialUI from './views/test'
import {
  BrowserRouter as Router,
  Route,
  Redirect
} from "react-router-dom";


const AppLoader = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => {
    return {
      isLoading: state.menu.isLoading,
    }
  });

  useEffect(() => {
    if (!isLoading) {
      dispatch(menuUpdate());
    }
  }, [])

  if (isLoading) return <div className="App"><CircularProgress /> </div>
  return (
    <div className="App">
      <Navbar />
      <MenuHeader />
      <TabsBar />
      <Footer />

    </div>
  )
}

const App = () => {
  const { Cart } = useSelector((state) => {
    return {
      isLoading: state.menu.isLoading,
      Cart: state.menu.cart
    }
  });
  return (
    <Router>
      <Route path='/' exact component={AppLoader} />
      <Route path="/checkout">
        {Cart.length > 0 ? <CheckOutForm /> : <Redirect to="/" />}
      </Route>
      

    </Router>
  );
}
export default App;
