import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import TextField from '@material-ui/core/TextField';

import HomeIcon from '@material-ui/icons/Home';
import firebase from "../firebase";
import { useSelector, useDispatch } from 'react-redux';
import { emptyCart } from '../store/counterslice'
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import check from '../assets/img/checkmark.gif';
import { useForm, Controller } from "react-hook-form";

import styles from "assets/jss/material-kit-react/views/loginPage.js";



import image from "assets/img/wooden2.jpg";

const useStyles = makeStyles(styles);


function orderId() {
  var date = new Date();
  var components = [
    date.getYear(),
    date.getMonth(),
    date.getDate(),
    date.getMilliseconds()
  ];

  let ID = components.join("");
  return ID
}

export function Confirmation(props) {

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  return (
    <GridContainer justify="center">
      <GridItem xs={12} md={6}  >
        <Card className={classes[cardAnimaton]}>
          <CardHeader color="primary" className={classes.cardHeader}>
            <h4 className="fontSetting">Order Confirmed</h4>
            

            <div className={classes.socialLine}>
              <Button
                justIcon
                href="https://instagram.com/salateen.uk?r=nametag"
                target="_blank"
                color="transparent"

              >
                <i className={"fab fa-instagram"} />
              </Button>
              <Button
                justIcon
                href="https://www.facebook.com/Salateen.uk/"
                target="_blank"
                color="transparent"

              >
                <i className={"fab fa-facebook"} />
              </Button>
              <Button
                justIcon
                href="https://g.page/Salateenuk?gm"

                color="transparent"

              >
                <i className={"fab fa-google-plus-g"} />
              </Button>
            </div>
          </CardHeader>
          <h6 className={classes.divider}>Your Order is recieved our representative will contact you soon.</h6>
          <CardBody justify="center">
            <img className="confimationImage" src={check} alt="checkout" />
            <h6 className={classes.divider}>On cinfirmation your order will be delivered with in 45 min</h6>

            <h6 className={classes.divider}>Your Order ID is {props.order}, On cinfirmation your order will be delivered with in 45 min</h6>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>)
}

export default function CheckOutPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const dispatch = useDispatch();

  const { handleSubmit, control, errors } = useForm();
  const [confirm, setConfirmation] = React.useState('false');
  const classes = useStyles();
  const { ...rest } = props;

  const { Cart } = useSelector((state) => {

    return {
      Cart: state.menu.cart,

    }
  });

  let subTotal = 0.00;
  if (Cart.length > 0) {
    subTotal = parseFloat(Cart.map(item => item.price).reduce((a, b) => a + b)).toFixed(2);
  }
  let tax = 15;
  let Taxtotal = parseFloat(subTotal * 1.15).toFixed(2);
  var ID = orderId();
  const onSubmit = values => {
    console.log(values);

    let now = Date.now();
    let subTotal = 0.00;
    if (Cart.length > 0) {
      subTotal = parseFloat(Cart.map(item => item.price).reduce((a, b) => a + b)).toFixed(2);
    }
    let tax = 15;
    let Taxtotal = parseFloat(subTotal / 100 % tax).toFixed(2);
    var thisOrder = {
      orderID: ID,
      date: now,
      orderdetails: Cart,
      address: values.address,

      email: values.email,
      name: values.name,
      Total: parseFloat(subTotal).toFixed(2),

      phoneNumber: values.contactnumber
    };
    console.log(thisOrder);
    firebase.database()
      .ref("orders/" + ID)
      .set(thisOrder);
    console.log("done ");
    setConfirmation('true');
    console.log(Confirmation);
    dispatch(emptyCart);
    setTimeout(() => window.location.href = "/", 5000)
  }



  return (
    <>
      {

        confirm === 'true' ? <Confirmation order={ID} /> :

          <div>
            <Header
              absolute
              color="transparent"
              brand="SALATEEN"
              rightLinks={<HeaderLinks />}
              {...rest}
            />
            <div
              className={classes.pageHeader}
              style={{
                backgroundImage: "url(" + image + ")",
                backgroundSize: "cover",
                backgroundPosition: "top center"
              }}
            >
              <div className={classes.container}>
                <GridContainer justify="center">

                  <GridItem xs={12} >






                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Card className={classes[cardAnimaton]}>
                        <CardHeader color="primary" className={classes.cardHeader}>
                          <h4 className="fontSetting">Checkout</h4>
                          <div className={classes.socialLine}>
                            <Button
                              justIcon
                              href="https://instagram.com/salateen.uk?r=nametag"
                              target="_blank"
                              color="transparent"

                            >
                              <i className={"fab fa-instagram"} />
                            </Button>
                            <Button
                              justIcon
                              href="https://www.facebook.com/Salateen.uk/"
                              target="_blank"
                              color="transparent"

                            >
                              <i className={"fab fa-facebook"} />
                            </Button>
                            <Button
                              justIcon
                              href="https://g.page/Salateenuk?gm"
                              target="_blank"
                              color="transparent"

                            >
                              <i className={"fab fa-google-plus-g"} />
                            </Button>
                          </div>
                        </CardHeader>
                        
                        <p className={classes.divider}>What you crave for</p>
                        <h5 className={classes.divider}>The resturant is only taking orders between 4:00PM - 11:00PM</h5>
                        <CardBody justify="center">

                          <div>
                            <Controller as={TextField} name="name" rules={{
                              required: true,
                              minLength: 4, maxLength: 12

                            }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <People />
                                  </InputAdornment>
                                ),
                              }}
                              control={control} defaultValue="" />
                            {errors.name && "name is required"}


                          </div>
                          <div>

                            <Controller as={TextField} name="email" rules={{
                              required: true,
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: 'invalid email address'
                              },
                              minLength: 4, maxLength: 35

                            }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Email />
                                  </InputAdornment>
                                ),
                              }}
                              control={control} defaultValue="" />
                            {errors.email && "email is required"}

                          </div>

                          <div>
                            <Controller as={TextField} name="contactnumber" rules={{
                              required: true,
                              minLength: 8, maxLength: 15

                            }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <People />
                                  </InputAdornment>
                                ),
                              }}
                              control={control} defaultValue="" />
                            {errors.contactnumber && "Contact number is required"}
                          </div>
                          <div>
                            <Controller as={TextField} name="address" rules={{
                              required: true,
                              minLength: 8, maxLength: 60

                            }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <HomeIcon />
                                  </InputAdornment>
                                ),
                              }}
                              control={control} defaultValue="" />
                            {errors.adress && "Adress is required"}

                          </div>


                          <div className="verticalLine">
                            <h3>Your Order</h3>
                            {Cart.length > 0 ? Cart.map((item, i) => (
                              <>
                                <Typography component="h3">{item.itemName}</Typography>
                                <br />
                                <Typography component="h4">Qty {item.qty} <span>Total {item.itemPrice}</span></Typography>
                                <br />
                                <br />
                                <Divider />
                              </>

                            )) : <Typography component="h3">No Item Cart</Typography>}
                            <br />
                            <br />
                            <Divider />
                            {/* <Typography component="h4">SubTotal <span>{subTotal}</span></Typography>
                            <Typography component="h4">Add {tax} % Tax <span>{Taxtotal}</span></Typography> */}
                            <Typography component="h4">Total <span>{(parseFloat(subTotal)).toFixed(2)}</span></Typography>
                            <Divider />
                          </div>
                          <div className="buttonCenter">
                            <Button onClick={() => window.location.href = "/"} color="danger" size="lg">
                              Go Back
                            </Button>
                            <Button type="submit" color="success" size="lg">
                              Confirm
                          </Button>
                          </div>
                        </CardBody>

                        <CardFooter>

                        </CardFooter>

                      </Card>
                    </form>




                  </GridItem>



                </GridContainer>
              </div>

            </div>
          </div>
      }
    </>
  );
}