import React from 'react'
import Carousel from "react-slick";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import '../../App.css';

import image1 from "assets/img/1.jpg";
import image2 from "assets/img/2.jpg";
import image3 from "assets/img/3.jpg";
import image4 from "assets/img/4.jpg";

const MenuHeader = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        adaptiveHeight: true,
        adaptiveWidth: true
    };
    return (
        <div className="menuHeader">
            <GridContainer>
                <GridItem xs={12}>

                    <Carousel {...settings}>
                        <div>
                            <img style={{ height: "100%", width: "100%" }}
                                src={image1}
                                alt="First slide"
                            />
                            <div className="slick-caption">
                                <h4>

                                </h4>
                            </div>
                        </div>
                        <div>
                            <img style={{ height: "100%", width: "100%" }}
                                src={image2}
                                alt="Second slide"
                            />
                            <div className="slick-caption">
                                <h4>
                                </h4>
                            </div>
                        </div>
                        <div>
                            <img style={{ height: "100%", width: "100%" }}
                                src={image3}
                                alt="Third slide"
                            />
                            <div className="slick-caption">
                                <h4>
                                </h4>
                            </div>
                        </div>
                        <div>
                            <img style={{ height: "100%", width: "100%" }}
                                src={image4}
                                alt="Fourth slide"
                            />
                            <div className="slick-caption">
                                <h4>
                                </h4>
                            </div>
                        </div>

                    </Carousel>

                </GridItem>
            </GridContainer>
        </div>
    )
};

export default MenuHeader