import React from 'react'
import classNames from "classnames";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import { makeStyles } from "@material-ui/core/styles";
import SubCat from './SubCat';
import { useSelector } from 'react-redux'
import styles from "assets/jss/material-kit-react/views/landingPage.js";

const useStyles = makeStyles(styles);

const TabsBar = () => {
    const classes = useStyles();
    const { cat } = useSelector((state) => {
        return {
            cat: state.menu.cat,
            isLoading: state.menu.isLoading,
        }
    });
    return (
        <div id="Menu" className={classes.section}>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <CustomTabs
                    headerColor="primary"
                    tabs={
                        cat.map((data, i) => {
                            const dataReturn = {
                                tabName: data,
                                tabContent: (<SubCat Cat={data} key={i} />)

                            }
                            return dataReturn
                        })
                    }
                />
            </div>

        </div>

    )
}

export default TabsBar
