import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import IconButton from '@material-ui/core/IconButton';
import AddShoppingCart from '@material-ui/icons/AddShoppingCart'
import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";
import "../../assets/css/ImageControl.css";
import { addToCart } from '../../store/counterslice'
import { useDispatch } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const useStyles = makeStyles(imagesStyles);




const SubCatCard = (props) => {


  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const dispatch = useDispatch();
  const classes = useStyles();

  const onClickAddToCart = (item, price) => {
    if (props.Data.Addon) {
      console.log(props.Data);
      setOpen(true);

    }

    else {

      let cartData = {
        itemName: item,
        itemPrice: price,
        Addon: '',
        Addon_Price: '',
        price: price,
        qty: 1,
        isAddon: false,
      }
      dispatch(addToCart(cartData))
    }
  }


  const [open, setOpen] = React.useState(false);

  const handleConfirm = () => {
    let cartData = {
      itemName: props.Data.Name,
      itemPrice: props.Data.Price + props.Data.Addon.price,
      Addon: props.Data.Addon.D1,
      Addon_Price: props.Data.Addon.price,
      price: props.Data.Price + props.Data.Addon.price,
      isAddon: true,
      qty: 1
    }
    dispatch(addToCart(cartData))

    setOpen(false);
  };
  const handleCancel = () => {
    let cartData = {
      itemName: props.Data.Name,
      itemPrice: props.Data.Price,
      Addon: '',
      Addon_Price: '',
      price: props.Data.Price,
      isAddon: false,
      qty: 1
    }
    dispatch(addToCart(cartData))
    setOpen(false);
  };
  const handleClose = () => {

    setOpen(false);
  };
  return (
    <>

      <GridItem xs={12} sm={6} md={4} lg={3} justify="center">
        <Card className={classes[cardAnimaton]}>
          <CardHeader color="primary" className="cardNAmeHeader Center">
            <h4 className="fontSetting">{props.Data.Name}</h4>
          </CardHeader>
          {
            props.Data.Detail_1.length > 0 ? <CardBody>
              <h6>{props.Data.Detail_1}</h6>
              <h6>{props.Data.Detail_2}</h6>
            </CardBody> : null
          }

          <CardFooter className='Center'>
            <h3 className="Price">Price &#163; {props.Data.Price.toFixed(2)}/-</h3>
            <IconButton className="AddShopingCartButton" onClick={() => onClickAddToCart(props.Data.Name, props.Data.Price)}>
              <AddShoppingCart />
            </IconButton>
          </CardFooter>
        </Card>
      </GridItem>
      <div>
        {props.Data.Addon && (<Dialog open={open} color="primary" onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Addons</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Add &#163; {props.Data.Addon.price} for {props.Data.Addon.D1}
              <p>{props.Data.Addon.D2}</p>
            </DialogContentText>

          </DialogContent>
          <DialogActions>

            <Button onClick={handleCancel} className="AddonDialogButton" color="primary">
              Confirm without adding
          </Button>
            <Button onClick={handleConfirm} className="AddonDialogButton" color="primary">
              Add
          </Button>
          </DialogActions>
        </Dialog>)}

      </div>


    </>
  );
};

export default SubCatCard;
