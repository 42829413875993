import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


// @material-ui/icons
import Kitchen from "@material-ui/icons/Kitchen";
import EmojiEmotions from "@material-ui/icons/EmojiEmotions";
import HighQuality from "@material-ui/icons/HighQuality";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);



const About = (props) => {
  const classes = useStyles();
  return (


    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Hello from Salateen</h2>
          <h5 className={classes.description}>
            Right in the heart of Bolton’s business hub, there is a little taste of South Asia. Step into Salateen for casual dining experience. It’s an Ideal place for casual dinners with friends and family, celebratory dinners or relaxing evening meet-ups.
          </h5>
          <h5 className={classes.description}>
            Salateen brings you wide range of cuisine with authentic South Asian taste that will make you come back again and again.  So bring your friends or family to our outlet in Bolton to enjoy your evening meals with us or order online from the comfort of your home.
            </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Originality"
              description="Our Original Recipies Coming from the ancient City of Peshawar and the Royal Mughal Cuisine Heritage"
              icon={Kitchen}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Great Quality"
              description="No compromise on ingredients Quality and a consistant flavour profile"
              icon={HighQuality}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Welcoming Staff"
              description="Live cooking Meals and professional staff"
              icon={EmojiEmotions}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>


  );
}

export default About;



