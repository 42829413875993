import * as firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyAr2LFhWb_Bq9mr_urLNV6pYPoE5gXxns8",
  authDomain: "salateen-website.firebaseapp.com",
  databaseURL: "https://salateen-website.firebaseio.com",
  projectId: "salateen-website",
  storageBucket: "salateen-website.appspot.com",
  messagingSenderId: "188170617485",
  appId: "1:188170617485:web:9db0c123d90d05927ad8c4",
  measurementId: "G-3L3D64Z1HC"
};

firebase.initializeApp(firebaseConfig);


export default firebase;
