/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { Link } from 'react-router-dom'
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";


import styles from "assets/jss/material-kit-react/views/components.js";


const useStyles = makeStyles(styles);


export default function Footer(props) {
  const [width, setWidth] = React.useState(0);
  React.useEffect(() => {
    function realTimeWidth() {
      setWidth(window.innerWidth)
    }
    window.addEventListener("resize", realTimeWidth);
    realTimeWidth()
  })
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.textCenter + " " + classes.sharingArea}>
          <GridContainer justify="center">
            <h3>Come visit Us have a feast!</h3>
          </GridContainer>
          <GridContainer justify="center" >
            <GridItem xs={12} md={4} >
              <p><i class="fas fa-map-marker-alt"></i>&nbsp; 250 st Helens Bolton BL3 3PZ</p>
              <p><i class="fas fa-mobile-alt"></i>&nbsp; 01204 417892</p>
              <p><i class="fas fa-mobile-alt"></i>&nbsp; 01204 658999</p>
              <p style={{ cursor: "pointer" }} onClick={() => window.open("https://api.whatsapp.com/send?phone=07599917417", '_blank')}  ><i className="fab fa-whatsapp"></i>&nbsp; 075 99917417</p>
              <p><i class="fas fa-clock"></i>&nbsp; Timing: 4:00 PM - 11:00 PM</p>

              <a
                href="https://g.page/Salateenuk?gm"

                target="_blank"
              >
                <i className={classes.socials + " fab fa-google-plus-g"} />
              </a>
              <a
                href="https://www.facebook.com/Salateen.uk/"

                target="_blank"
              >

                <i className={classes.socials + " fab fa-facebook"} />
              </a>
              <a
                href="https://instagram.com/salateen.uk?r=nametag"

                target="_blank"
              ><i className={classes.socials + " fab fa-instagram"}></i></a>




            </GridItem>

            <GridItem xs={12} md={4}>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15282225.79979123!2d73.7250245393691!3d20.750301298393563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30635ff06b92b791%3A0xd78c4fa1854213a6!2sIndia!5e0!3m2!1sen!2sin!4v1587818542745!5m2!1sen!2sin" width="100%" height="100%" frameborder="0" style={{ border: "1", padding: "2%" }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </GridItem>
            <GridItem xs={8} md={4} >
              <div className="fot-margin">
                {width >= '756' ? <> <h3>Get your app today!</h3><img src={require('../../assets/img/imageDownload.png')} style={{ width: "50%" }} /></> : <> <h5>Get your app today!</h5> <img src={require('../../assets/img/imageDownload.png')} style={{ width: "50%" }} /></>}
              </div>
              <br />
            </GridItem>
          </GridContainer>
        </div>

      </div>
      <footer className={footerClasses + 'color-white'}>
        <div className={classes.container}>
          <div className={classes.left}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
              </ListItem>
            </List>
          </div>
          <div className={classes.right}>
            &copy; {1900 + new Date().getYear()} , made with{" "}
            <Favorite className={classes.icon} /> for{" "}

            Salateen
        </div>
        </div>
      </footer>
    </>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
