import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import firebase from '../firebase';

export const menuUpdate = createAsyncThunk(
    "menu/menuUpdate",
    async (value, thunkAPI) => {
        let data = { Items: "data", cat: "catData" };
        const restDAta = firebase.database().ref("Menu")
        await restDAta.once("value", (snapshot) => {
            data = snapshot.val();
        })
        return data
    });

export const MenuSlice = createSlice({
    name: "menu",
    initialState: {
        count: 0,
        cat: [],
        cart: [],
        Items: [],
        isLoading: false,
        error: "",
        currentItems: [],

    },
    reducers: {
        addToCart: (state, action) => {
            let checkCart = state.cart.some(data => data.itemName === action.payload.itemName && data.isAddon === action.payload.isAddon)
            if (checkCart) {
                let indexOfItem = state.cart.findIndex(data => data.itemName === action.payload.itemName && data.isAddon === action.payload.isAddon)
                state.cart[indexOfItem].qty += 1;
                state.cart[indexOfItem].price = (state.cart[indexOfItem].qty * state.cart[indexOfItem].itemPrice).toFixed(2)
            } else {
                state.cart = [...state.cart, action.payload]
            };
        },
        removeFromCart: (state, action) => {
            let cart = state.cart;
            cart.splice(action.payload, 1);
            state.cart = cart;
        },
        AddQtyCart: (state, action) => {
            state.cart[action.payload].qty += 1;
            state.cart[action.payload].price = Number((state.cart[action.payload].qty * state.cart[action.payload].itemPrice).toFixed(2))
        },
        LessQtyCart: (state, action) => {

            if (state.cart[action.payload].qty > 1) {
                state.cart[action.payload].qty -= 1;
                state.cart[action.payload].price = Number((Number(state.cart[action.payload].qty * state.cart[action.payload].itemPrice).toFixed(2)))
            }
            else {
                let cart = state.cart;
                cart.splice(action.payload, 1);
                state.cart = cart;
            }
        },
        filterSubCat: (state, action) => {
            state.Items.filter(data => data.Cat === action.payload.Cat)
        },
        emptyCart: (state, action) => {
            state.cart = []
        },
    },
    extraReducers: {
        [menuUpdate.fulfilled]: (state, action) => {
            state.cat = action.payload.cat;
            state.Items = action.payload.Items;
            state.isLoading = false;
        },
        [menuUpdate.pending]: (state) => {
            state.isLoading = true;
        },
        [menuUpdate.rejected]: (state, action) => {
            console.log("rejected ", action);
            state.isLoading = false;
            state.error = "Error in Update counter";
        },
    }


});

export const { setCat, setItems, addToCart, AddQtyCart, LessQtyCart, removeFromCart, emptyCart } = MenuSlice.actions;
export default MenuSlice.reducer;