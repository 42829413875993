import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import ListItem from '@material-ui/core/ListItem';
import Button from "components/CustomButtons/Button.js";
import Badge from 'components/Badge/Badge.js';
import { useDispatch, useSelector } from 'react-redux';
import { AddQtyCart, LessQtyCart, removeFromCart } from '../../store/counterslice'
import { Link } from "react-router-dom";
const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  buttonQty: {
    width: '10px'
  }
}));

export default function Cart() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const { Cart } = useSelector((state) => {
    return {
      Cart: state.menu.cart,
    }
  });
  const handleDrawerClose = () => {
    setOpen(!open);
  };
  const AddQtytoCart = (index) => {
    dispatch(AddQtyCart(index));
  }
  const LessQtytoCart = (index) => {
    dispatch(LessQtyCart(index));
  }
  const removeCart = (index) => {
    dispatch(removeFromCart(index))
  }
  let listClassName = "cartLengh";
  if (Cart.length > 2) {
    listClassName = "cartLengh cartScroll"
  };

  let subTotal = 0.00;
  if (Cart.length > 0) {
    let qtyAdd = 0.0
    //  subTotal = parseFloat(Cart.map(item => {
    //    qtyAdd += item.price;
    //   console.log(item.price);
    //   console.log(qtyAdd)
    // return qtyAdd })).toFixed(2);

    Cart.map(item => qtyAdd += item.price);
    subTotal = parseFloat(qtyAdd).toFixed(2);

  }
  let tax = 15;
  let Taxtotal = parseFloat(subTotal * 1.15 - subTotal).toFixed(2);

  return (
    <div className={classes.root}>
      <Button
        color="transparent"
        aria-label="open drawer"
        edge="end"
        onClick={handleDrawerOpen}
        className={clsx(open)}
      >
        <ShoppingCartIcon />
        {Cart.length > 0 ? <Badge color="success">{Cart.length}</Badge> : <Badge color="danger">{Cart.length}</Badge>}
      </Button>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List className={listClassName}>
          {Cart.length > 0 ? Cart.map((item, i) => (
            <>
              <ListItem key={i}>
                <IconButton onClick={() => removeCart(i)}><Clear /></IconButton>
                <Typography component="h3">{item.itemName}</Typography>
              </ListItem>
              {item.isAddon ? <Typography component="h5">with Addon</Typography> : null}
              <ListItem>
                <Typography component="h6"> Qty</Typography>
                <IconButton onClick={() => AddQtytoCart(i)}><AddIcon /></IconButton>
                <Typography component="h6">{item.qty}</Typography>
                <IconButton onClick={() => LessQtytoCart(i)}><RemoveIcon /></IconButton>
              </ListItem>
              <Typography component="h6" className="priceForCart"> Total {item.price}</Typography>
              <Divider />
            </>

          )) : <Typography component="h3">No Item Cart</Typography>}
        </List>
        <Divider />
        {/* <Typography component="h4">SubTotal <span>{subTotal}</span></Typography> */}
        {/* <Typography component="h4">Add {tax} % Tax <span>{Taxtotal}</span></Typography> */}
        <Typography component="h4">Total <span>{(parseFloat(subTotal)).toFixed(2)}</span></Typography>
        <Divider />
        {Cart.length > 0 ? <Button color="primary" className="CheckoutButton" to="/checkout"> <Link className="CheckoutButton" to="/checkout"> CheckOut</Link> </Button> : <Button color="primary" disabled> <Link className="CheckoutButton" to="/checkout"> CheckOut</Link> </Button>}
        <Button color="danger" onClick={handleDrawerClose}>Cancel</Button>
      </Drawer>
    </div>
  );
}