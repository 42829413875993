import React from "react";
import { useForm, Controller } from "react-hook-form";

import { TextField, Checkbox } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import People from "@material-ui/icons/People";

function UserFormWithFormikMaterialUI() {
    const methods = useForm();
    const { handleSubmit, control, errors } = methods;
    const onSubmit = data => { console.log(data.TextField); console.log(data.MyCheckbox) }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {/* Option 1: pass a component to the Controller. */}
            <Controller as={TextField} name="TextField" rules={{
                required: true,
                minLength: 6, maxLength: 12

            }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <People />
                        </InputAdornment>
                    ),
                }}
                control={control} defaultValue="" />
            {errors.TextField && "First name is required"}

            {/* Option 2: use render props to assign events and value */}
            <Controller
                name="MyCheckbox"
                control={control}
                defaultValue={false}
                rules={{ required: true }}
                render={props =>
                    <Checkbox
                        onChange={e => props.onChange(e.target.checked)}
                        checked={props.value}
                    />
                } // props contains: onChange, onBlur and value
            />
            {errors.firstName && "First name is required"}
            <button type='submit' >submit</button>
        </form>
    );
}

export default UserFormWithFormikMaterialUI;